import { Button, Modal, Tooltip } from 'src/design-system'
import { Toaster } from 'react-hot-toast'
import * as React from 'react'
import { Flag } from '@phosphor-icons/react'
import { Editor, EditorContext } from 'components/atoms/editor'
import { ModifiableCommentAttributes } from 'store/modules/comments'
import { store } from 'store/index'
import { successToast } from 'app/packs/src/utils/success-toast'
import { trackEvent } from '../../services/event-tracker'

export type SkillSuggestionDialogProps = {
  orgName: string
  skillVariantId: string
  skillName: string
  source: string
  children: React.ReactElement
}

export const SkillSuggestionDialog: React.VFC<SkillSuggestionDialogProps> = (
  props
) => {
  const { orgName, skillVariantId, skillName, source } = props
  const [invalid, setInvalid] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const editor = React.useRef<EditorContext>(null)

  const [formState, setFormState] = React.useState<ModifiableCommentAttributes>(
    {
      commentableId: skillVariantId,
      commentableType: 'SkillVariant',
      content: '',
    }
  )

  const onClick = async () => {
    if (formState['content'] === '') {
      setInvalid(true)
    } else {
      const result = await store.comments.create(formState, { source })
      if (result.success) {
        setInvalid(false)
        setOpen(false)
        successToast('Your suggestion has been sent successfully.')
      } else {
        setInvalid(false)
      }
    }
  }

  if (!skillVariantId) return null

  return (
    <Modal.Root
      className="p-8 w-[752px]"
      title="Suggest an improvement"
      open={open}
      trigger={
        <Button
          className="bg-white p-0 text-theme-40 whitespace-normal"
          type="button"
          name="Suggest an improvement"
          data-testid="suggest-improvement-button"
        >
          <Tooltip content="Suggest an improvement to this skill">
            <Flag alt="" className="h-3.5 text-gray-600 w-3.5" weight="bold" />
          </Tooltip>
        </Button>
      }
      onOpenChange={(open) => {
        setOpen(open)
        if (open)
          trackEvent('$track_skill_suggestion_dialog_opened', {
            source,
          })
      }}
    >
      <h4 className="font-bold mb-2 text-gray-900 text-lg">
        Suggest an improvement to {skillName}
      </h4>
      <p className="mb-6 text-gray-600 text-sm">
        Suggestions will be visible to{' '}
        <span className="text-gray-900">admins and editors</span> of this
        content at {orgName}.
      </p>
      <Editor
        autoFocus
        className="border-gray-200 focus-within:border-theme-60 focus-within:!shadow-none px-4 py-3 w-full"
        invalid={invalid}
        name="content"
        onChange={(value) =>
          setFormState((formState) => ({ ...formState, content: value }))
        }
        placeholder="Add your feedback..."
        ref={editor}
        rows={3}
      />
      <div className="flex flex-row justify-end gap-2 mt-6">
        <Button
          onClick={() => {
            setOpen(false)
          }}
          type="button"
          variant="outline"
        >
          Cancel
        </Button>
        <Button onClick={onClick} type="button">
          Send Feedback
        </Button>
      </div>
    </Modal.Root>
  )
}
